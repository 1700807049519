import revive_payload_client_4sVQNw7RlN from "/Users/ozan/Code/Projects/CAT/cat-website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/ozan/Code/Projects/CAT/cat-website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/ozan/Code/Projects/CAT/cat-website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/ozan/Code/Projects/CAT/cat-website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/ozan/Code/Projects/CAT/cat-website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/Users/ozan/Code/Projects/CAT/cat-website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/ozan/Code/Projects/CAT/cat-website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_W86eSGFqyZ from "/Users/ozan/Code/Projects/CAT/cat-website/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/ozan/Code/Projects/CAT/cat-website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import captcha_2ixTAsa90i from "/Users/ozan/Code/Projects/CAT/cat-website/plugins/captcha.ts";
import form_client_TkIylfZzkE from "/Users/ozan/Code/Projects/CAT/cat-website/plugins/form.client.ts";
import modal_UEFnUXzgTX from "/Users/ozan/Code/Projects/CAT/cat-website/plugins/modal.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_W86eSGFqyZ,
  chunk_reload_client_UciE0i6zes,
  captcha_2ixTAsa90i,
  form_client_TkIylfZzkE,
  modal_UEFnUXzgTX
]