import { default as contactZSSlQZvnH7Meta } from "/Users/ozan/Code/Projects/CAT/cat-website/pages/contact.vue?macro=true";
import { default as index8hSIJxkYv0Meta } from "/Users/ozan/Code/Projects/CAT/cat-website/pages/index.vue?macro=true";
import { default as portfolioYwAtQzCCWXMeta } from "/Users/ozan/Code/Projects/CAT/cat-website/pages/portfolio.vue?macro=true";
import { default as _91id_93XOm3WyqgkVMeta } from "/Users/ozan/Code/Projects/CAT/cat-website/pages/services/[id].vue?macro=true";
export default [
  {
    name: contactZSSlQZvnH7Meta?.name ?? "contact",
    path: contactZSSlQZvnH7Meta?.path ?? "/contact",
    meta: contactZSSlQZvnH7Meta || {},
    alias: contactZSSlQZvnH7Meta?.alias || [],
    redirect: contactZSSlQZvnH7Meta?.redirect,
    component: () => import("/Users/ozan/Code/Projects/CAT/cat-website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: index8hSIJxkYv0Meta?.name ?? "index",
    path: index8hSIJxkYv0Meta?.path ?? "/",
    meta: index8hSIJxkYv0Meta || {},
    alias: index8hSIJxkYv0Meta?.alias || [],
    redirect: index8hSIJxkYv0Meta?.redirect,
    component: () => import("/Users/ozan/Code/Projects/CAT/cat-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: portfolioYwAtQzCCWXMeta?.name ?? "portfolio",
    path: portfolioYwAtQzCCWXMeta?.path ?? "/portfolio",
    meta: portfolioYwAtQzCCWXMeta || {},
    alias: portfolioYwAtQzCCWXMeta?.alias || [],
    redirect: portfolioYwAtQzCCWXMeta?.redirect,
    component: () => import("/Users/ozan/Code/Projects/CAT/cat-website/pages/portfolio.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XOm3WyqgkVMeta?.name ?? "services-id",
    path: _91id_93XOm3WyqgkVMeta?.path ?? "/services/:id()",
    meta: _91id_93XOm3WyqgkVMeta || {},
    alias: _91id_93XOm3WyqgkVMeta?.alias || [],
    redirect: _91id_93XOm3WyqgkVMeta?.redirect,
    component: () => import("/Users/ozan/Code/Projects/CAT/cat-website/pages/services/[id].vue").then(m => m.default || m)
  }
]